import React from "react";

// reactstrap components
import { Col, Container, Row } from "reactstrap";

// core components

function HowToHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header page-header-fit"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("../../../images/bgs/citylove_map_grey.jpg") + ")"
        }}
      >
        <div className="content-center">
          <Container>
            <div
              className="ml-auto mr-auto 0mb-5"
              style={{
                width: 150,
                height: 150,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(" +
                  require("../../../images/logos/citylove_logo_full.svg") +
                  ")"
              }}
            ></div>
            <h2 className="title">
                Wie funktioniert citylove?
            </h2>
            <h5 className="">
                Covid19 – Freue dich schon jetzt auf die Zeit danach. Oder verschenke Freude. Gleichzeitig hilfst du regionalen Unternehmen.
            </h5>
            <Row>
              <Col md={3}>
                <div className="info">
                  <div className="">
                    <h4 className="info-title">Partner werden</h4>
                    <p className="">
                    Dein Unternehmen schickt uns alle Informationen für deinen Gutschein. Das Team von citylove stellt den Gutschein online.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="info">
                  <div className="">
                    <h4 className="info-title">Gutschein kaufen</h4>
                    <p className=" ">
                    Du kaufst oder schenkst Gutscheine deiner Lieblingsbetriebe und unterstützt die regionale Wirtschaft.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="info">
                  <div className="">
                    <h4 className="info-title">Soforthilfe</h4>
                    <p className="">
                    citylove leitet den Erlös direkt an den Partnerbetrieb weiter und hilft so unmittelbar durch die schwierige Zeit.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="info">
                  <div className="">
                    <h4 className="info-title">Gutschein einlösen</h4>
                    <p className="">
                    Auch diese Krise geht irgendwann vorbei. Freue dich schon jetzt, denn nun kannst du deine Gutscheine einlösen.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default HowToHeader;
