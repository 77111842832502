import React from "react";
import Layout from "../components/layout";

import HowToHeader from "../components/Custom/Headers/HowToHeader";
import CustomNavbar from "../components/Custom/Navbar";

import withUIKit from "../withUIKit";
import { Container } from "reactstrap";
import FaqContainer from "../components/Custom/Faq/FaqContainer";
import { LocalizedButton } from "../components/language/LocalizedButton";


const HowTo = props => {

  return (
    <Layout {...props} >
      <CustomNavbar {...props} />
      <HowToHeader />
      <div className="wrapper">
       
        {/* <div className="section text-center landing-section">
          <h2 className="mb-5 text-center">Häufig gestellte Fragen</h2>
          <VerticalTabs data={stepByStepData} />
        </div>

        <hr /> */}

        <div className="section landing-section">
          <Container>
                <h2 className="mb-5 text-center">Häufig gestellte Fragen</h2>
                <FaqContainer />
          </Container>
        </div>

        <hr />

        <div className="section landing-section text-center">
          <Container className="ml-auto mr-auto">
            {/* <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/"
            >
              Gutscheine finden
            </LocalizedButton> */}
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/joinin"
            >
              Partner werden
            </LocalizedButton>
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/disclaimer"
            >
              AGB
            </LocalizedButton>
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/about"
            >
              Über
            </LocalizedButton>
          </Container>
        </div>

        <hr />

      </div>
    </Layout>
  );
};

export default withUIKit(HowTo);
